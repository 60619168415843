<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <div>
        <div class="d-flex">
          <div
            v-if="!promocodeId || promocodeId === 0"
            class="mr-5"
          >
            <div class="mb-2 h6">
              Генерация промокодов
            </div>

            <b-form-checkbox
              id="checkbox-1"
              v-model="generatePromocodes"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              Сгенерировать промокоды
            </b-form-checkbox>
          </div>

          <div v-if="generatePromocodes">
            <div class="mb-2 h6">
              Количество промокодов
            </div>

            <b-form-input
              v-model.number="promocodesCount"
              type="number"
              min="0"
              placeholder="Количество"
              @input="validateField('promocodesCount', promocodesCount)"
            />

            <div
              v-if="errors['promocodesCount']"
              class="mt-2 text-danger small"
            >
              {{ errors['promocodesCount'][0] }}
            </div>
          </div>

          <div v-else>
            <div class="mb-2 h6">
              Название промокода
            </div>

            <b-form-input
              v-model.trim="code"
              placeholder="Введите название"
              @input="validateField('promocode.code', promocode.code)"
            />

            <div
              v-if="errors['promocode.code']"
              class="mt-2 text-danger small"
            >
              {{ errors['promocode.code'][0] }}
            </div>
          </div>

          <div
            class="checkbox-queue"
            :class="{'checkbox-queue-2': !!promocodeId}"
          >
            <b-form-checkbox
              v-model="promocodeQueue"
              value="1"
              unchecked-value="0"
              :disabled="!!promocodeId"
            >
              Для очереди
            </b-form-checkbox>
          </div>
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Тип промокода
          </div>

          <b-form-radio-group
            v-model="promocodeType"
            :options="PROMO_CODES_TYPES"
            class="mb-3"
            value-field="alias"
            text-field="title"
            disabled-field="notEnabled"
            @input="changePromocodeType"
          />

          <div
            v-if="errors['promocodeType']"
            class="mt-2 text-danger small"
          >
            {{ errors['promocodeType'][0] }}
          </div>
        </div>

        <div class="mt-3">
          <div class="mb-2 h6">
            Тип скидки
          </div>

          <b-form-radio-group
            v-model="priceType"
            :options="DISCOUNT_TYPE"
            class="mb-3"
            value-field="alias"
            text-field="title"
            disabled-field="notEnabled"
            @input="changePriceType"
          />

          <div
            v-if="errors['priceType'] && priceType === null"
            class="mt-2 text-danger small"
          >
            {{ errors['priceType'][0] }}
          </div>
        </div>

        <div
          v-if="priceType"
          class="mt-3"
        >
          <div
            v-if="priceType === 'fixed'"
            class="w-50"
          >
            <div class="mb-2 h6">
              Покрытие {{ promocodeType === 'perc' ? '%' : 'руб.' }}
            </div>

            <b-input
              v-model.number="promocode.valuePrice"
              type="number"
              min="0"
              placeholder="Покрытие"
              @input="validateField('promocode.valuePrice', promocode.valuePrice)"
            />

            <div
              v-if="errors['promocode.valuePrice']"
              class="mt-2 text-danger small"
            >
              {{ errors['promocode.valuePrice'][0] }}
            </div>
          </div>

          <div v-if="priceType === 'dynamic'">
            <template v-for="(consultation, index) in consultations">
              <b-row
                v-if="consultation.inputAdded"
                :key="consultation.id"
                class="mb-3"
              >
                <b-col md="6">
                  <div class="mb-2 h6">
                    Количество консультаций
                  </div>

                  <b-input
                    v-model.number="promocode[consultation.consultationsKey]"
                    type="number"
                    min="0"
                    placeholder="Покрытие"
                    @input="validateField(`promocode.${consultation.consultationsKey}`, promocode[consultation.consultationsKey])"
                  />
                  <div
                    v-if="errors[`promocode.${consultation.consultationsKey}`]"
                    class="mt-2 text-danger small"
                  >
                    {{ errors[`promocode.${consultation.consultationsKey}`][0] }}
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="mb-2 h6">
                    Покрытие {{ promocodeType === 'perc' ? '%' : 'руб.' }}
                  </div>

                  <b-input
                    v-model.number="promocode[consultation.priceKey]"
                    type="number"
                    min="0"
                    placeholder="Покрытие"
                    @input="validateField(`promocode.${consultation.priceKey}`, promocode[consultation.priceKey])"
                  />
                  <div
                    v-if="errors[`promocode.${consultation.priceKey}`]"
                    class="mt-2 text-danger small"
                  >
                    {{ errors[`promocode.${consultation.priceKey}`][0] }}
                  </div>
                </b-col>
              </b-row>

              <b-button
                v-if="!consultation.inputAdded && consultations[index - 1].inputAdded"
                :key="consultation.id"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
                class="mt-2"
                @click="consultation.addInputAddedMethod"
              >
                Добавить скидку
              </b-button>
            </template>
          </div>

          <div
            v-if="errors['priceType']"
            class="mt-2 text-danger small"
          >
            {{ errors['priceType'][0] }}
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Признак промокода
        </div>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="promocode.attribute"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="0"
            @input="validateField('promocode.attribute', promocode.attribute)"
          >
            Мать и дитя
          </b-form-radio>
          <b-form-radio
            v-model="status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="1"
          >
            Доктис
          </b-form-radio>
          <b-form-radio
            v-model="status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="2"
          >
            Депозит
          </b-form-radio>
        </b-form-group>

        <div
          v-if="errors['promocode.attribute']"
          class="mt-2 text-danger small"
        >
          {{ errors['promocode.attribute'][0] }}
        </div>
      </div>

      <div
        v-if="promocode.attribute === 2"
        class="mt-3"
      >
        <div class="mb-2 h6">
          Признак депозита
        </div>

        <b-form-radio-group
          v-model="promocode.status"
          :options="SIGN_OF_DEPOSIT"
          class="mb-3"
          value-field="id"
          text-field="title"
          disabled-field="notEnabled"
          @input="validateField('promocode.status', promocode.status)"
        />

        <div
          v-if="errors['promocode.status']"
          class="mt-2 text-danger small"
        >
          {{ errors['promocode.status'][0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Срок действия промокода
        </div>

        <b-form-radio-group
          v-model="termType"
          :options="PROMO_CODE_VALIDITY_PREIOD"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeTermType"
        />

        <div
          v-if="errors['termType']"
          class="mt-2 text-danger small"
        >
          {{ errors['termType'][0] }}
        </div>
      </div>

      <div
        v-if="termType === 'actual'"
        class="mt-3 mb-5"
      >
        <b-row>
          <b-col md="6">
            <div class="mb-2 h6">
              Дата начала
            </div>

            <base-date-picker
              v-model="promocode.startDate"
              :error="!!errors['promocode.startDate']"
              :errors="errors['promocode.startDate']"
              @change="validateField('promocode.startDate', promocode.startDate)"
            />
          </b-col>

          <b-col md="6">
            <div class="mb-2 h6">
              Дата окончания
            </div>

            <base-date-picker
              v-model="promocode.endDate"
              :error="!!errors['promocode.endDate']"
              :errors="errors['promocode.endDate']"
              @change="validateField('promocode.endDate', promocode.endDate)"
            />
          </b-col>
        </b-row>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Тип промокода по количеству применений
        </div>

        <b-form-radio-group
          v-model="useType"
          :options="NUMBER_OF_APPLICATIONS"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeUseType"
        />

        <div
          v-if="errors['useType']"
          class="mt-2 text-danger small"
        >
          {{ errors['useType'][0] }}
        </div>
      </div>

      <div
        v-if="useType === 'multiple'"
        class="mt-3"
      >
        <div class="mb-2 h6">
          Количество применений
        </div>

        <b-input
          v-model.number="countUsing"
          placeholder="Количество применений"
          type="number"
          min="0"
          class="mt-2 w-50"
          @input="validateField('promocode.countUsing', promocode.countUsing)"
        />

        <div
          v-if="errors['promocode.countUsing']"
          class="mt-2 text-danger small"
        >
          {{ errors['promocode.countUsing'][0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Количество пользователей
        </div>

        <b-form-radio-group
          v-model="countType"
          :options="COUNT_TYPES"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeCountType"
        />

        <div v-if="countType === 'multiple'">
          <b-input
            v-model.number="promocode.countUsers"
            type="number"
            min="0"
            placeholder="Количество"
            class="w-50 mt-2"
            @input="validateField('promocode.countUsers', promocode.countUsers)"
          />

          <div
            v-if="errors['promocode.countUsers']"
            class="mt-2 text-danger small"
          >
            {{ errors['promocode.countUsers'][0] }}
          </div>
        </div>

        <div
          v-if="errors['countType']"
          class="mt-2 text-danger small"
        >
          {{ errors['countType'][0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Покрытие специализаций
        </div>

        <b-form-radio-group
          v-model="specType"
          :options="SPEC_TYPES"
          class="mb-3"
          value-field="alias"
          text-field="title"
          disabled-field="notEnabled"
          @input="changeSpecType"
        />

        <div
          v-if="errors['specType']"
          class="mt-2 text-danger small"
        >
          {{ errors['specType'][0] }}
        </div>
      </div>

      <div
        v-if="specType === 'use'"
        class="mt-2"
      >
        <CombinedTablesWithSearch
          :uniq-id="uniqId"
          title="Выбранные специализации"
          search-placeholder="Поиск по названию специализации"
          :selected-items-list="promocode.specializations"
          :items-list="specializationList"
          @onAdd="onSpecialisationAdd"
          @onRemove="onSpecialisationRemove"
        >
          <template #not-found-text>
            <small>Специализация не найдена</small>
          </template>
          <template #enter-text>
            <small>Введите название специализации</small>
          </template>
        </CombinedTablesWithSearch>
        <div
          v-if="errors['promocode.specializations']"
          class="mt-2 text-danger small"
        >
          {{ errors['promocode.specializations'][0] }}
        </div>
      </div>

      <div
        v-if="specType === 'doc'"
        class="mt-2"
      >
        <template>
          <CombinedTablesWithSearch
            :uniq-id="uniqId"
            title="Выбранные клиники"
            search-placeholder="Поиск по названию клиники"
            :selected-items-list="promocode.clinics"
            :items-list="clinics"
            :is-icon-change="true"
            @onAdd="onClinicAdd"
            @onRemove="onClinicRemove"
          >
            <template #not-found-text>
              <small>Клиника не найдена</small>
            </template>
            <template #enter-text>
              <small>Начните вводить название клиники</small>
            </template>
          </CombinedTablesWithSearch>
        </template>

        <hr class="my-3">

        <template v-if="isClinicLoading">
          <preloader />
        </template>
        <template v-else-if="promocode.clinics.length">
          <b-table-simple
            v-if="promocode.doctors.length"
            bordered
            small
          >
            <b-tbody>
              <b-tr
                v-for="doctor in promocode.doctors"
                :key="doctor.id"
              >
                <b-td>
                  {{ doctor.name }}
                </b-td>
                <b-td class="td-width">
                  <b-button
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="onDoctorRemove(doctor.id)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-form-input
            v-model="doctorSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск по Фамилия/Имя/Отчество"
            size="sm"
            trim
            class="mt-4 mb-2"
          />
          <div
            v-if="filteredDoctors.length"
            class="mb-4 filtered-doctors"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="doctor in filteredDoctorsSlice"
                  :key="doctor.id"
                >
                  <b-td>
                    {{ doctor.name }}
                  </b-td>
                  <b-td class="td-width">
                    <b-button
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      @click="onDoctorAdd(doctor.id)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div
            v-if="filteredDoctors.length"
            class="mt-3"
          >
            <div
              v-if="filteredDoctors.length > doctorPerPage"
              class="crm-pagination"
            >
              <b-pagination
                v-model="doctorCurrentPage"
                :per-page="doctorPerPage"
                :total-rows="filteredDoctors.length"
                class="pt-4"
              />
            </div>
          </div>

          <small
            v-if="doctorSearchString && !filteredDoctors.length"
            class="ml-2 mb-4"
          >
            Врач не найден
          </small>
        </template>
        <div
          v-if="errors['promocode.doctors']"
          class="mt-2 text-danger small"
        >
          {{ errors['promocode.doctors'][0] }}
        </div>
      </div>

      <div class="mt-3">
        <div class="mb-2 h6">
          Комментарий
        </div>

        <b-form-textarea
          id="comments"
          v-model="promocode.comment"
          placeholder="Введите комментарий"
          rows="3"
          max-rows="6"
          @input="validateField('promocode.comment', promocode.comment)"
        />
      </div>

      <div
        v-if="errors['promocode.comment']"
        class="mt-2 text-danger small"
      >
        {{ errors['promocode.comment'][0] }}
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  parseISO,
  formatISO,
} from '@evd3v/date-fns';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { showErrorCustomMessage, showValidationErrorMessage } from '@/helpers/messages';
import { v4 as uuidv4 } from 'uuid';
import Preloader from '@/components/Preloader';

import { clinicService } from '@/services';

import { BaseDatePicker } from '@/components/base';
import CombinedTablesWithSearch from '@/components/common/Tables/CombinedTablesWithSearch';

import {
  SPEC_TYPES,
  COUNT_TYPES,
  DISCOUNT_TYPE,
  SIGN_OF_DEPOSIT,
  PROMO_CODES_TYPES,
  NUMBER_OF_APPLICATIONS,
  PROMO_CODE_VALIDITY_PREIOD,
} from '@/helpers/consts';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { promocodeEditModalNewSchema } from '@/validation/zod/promocode-edit-modal-new/promocodeEditModalNew';

export default {
  name: 'PromocodeEditModal',
  components: {
    BaseDatePicker,
    CombinedTablesWithSearch,
    Preloader,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    promocodeId: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Добавление промокода',
    },
  },

  data() {
    return {
      SPEC_TYPES,
      COUNT_TYPES,
      DISCOUNT_TYPE,
      SIGN_OF_DEPOSIT,
      PROMO_CODES_TYPES,
      NUMBER_OF_APPLICATIONS,
      PROMO_CODE_VALIDITY_PREIOD,
      isOpen: true,
      isLoading: false,
      isSaving: false,
      generatePromocodes: false,
      promocodesCount: null,
      promocode: {
        id: 0,
        code: '',
        comment: '',
        isPerc: false,
        isFixed: false,
        startDate: null,
        endDate: null,
        countUsing: null,
        countUsers: null,
        specializations: [],
        doctors: [],
        clinics: [],
        valuePrice: null,
        secondaryPrice: null,
        thirdPrice: null,
        firstUse: null,
        secondaryUse: null,
        thirdUse: null,
        attribute: null,
        status: null,
      },
      doctorSearchString: '',
      doctorCurrentPage: 1,
      doctorPerPage: 5,
      doctorLimit: 5,
      isClinicLoading: false,
      promocodeType: null,
      promocodeQueue: 0,
      termType: null,
      useType: null,
      countType: null,
      specType: null,
      priceType: null,
      secondarySale: null,
      thirdSale: null,
      specializationList: [],
      clinics: [],
      doctors: [],
      uniqId: null,
      fieldsToValidate: ['promocode.comment', 'promocode.isPerc', 'promocode.isFixed', 'promocodeType', 'promocode.startDate',
        'promocode.attribute', 'promocode.valuePrice', 'termType', 'useType', 'countType', 'specType', 'priceType'],
      errors: {},
    };
  },
  computed: {
    code: {
      get() {
        return this.promocode.code;
      },
      set(value) {
        this.promocode.code = value.toUpperCase();
      },
    },
    countUsing: {
      get() {
        return this.promocode.countUsing;
      },
      set(value) {
        this.promocode.countUsing = value >= 0 ? value : 0;
      },
    },
    status: {
      get() {
        return this.promocode.attribute;
      },
      set(value) {
        this.promocode.attribute = value;
        this.promocode.status = null;
      },
    },
    consultations() {
      return [
        {
          id: 1,
          inputAdded: true,
          consultationsKey: 'firstUse',
          priceKey: 'valuePrice',
        },
        {
          id: 2,
          inputAdded: this.secondarySale,
          addInputAddedMethod: this.addSecondarySale,
          consultationsKey: 'secondaryUse',
          priceKey: 'secondaryPrice',
        },
        {
          id: 3,
          inputAdded: this.thirdSale,
          addInputAddedMethod: this.addThirdSale,
          consultationsKey: 'thirdUse',
          priceKey: 'thirdPrice',
        },
      ];
    },
    filteredDoctors() {
      return this.doctors.filter((d) => {
        if (this.doctorSearchString && !d.name.toLowerCase().includes(this.doctorSearchString.toLowerCase())) return false;
        return !this.promocode.doctors.find((dd) => dd.id === d.id);
      });
    },
    filteredDoctorsSlice() {
      return this.filteredDoctors.slice(this.doctorOffSet, this.doctorOffSet + this.doctorLimit);
    },
    doctorOffSet() {
      return (this.doctorCurrentPage - 1) * this.doctorLimit;
    },
  },
  watch: {
    'promocode.attribute': {
      handler(event) {
        if (event === 2) {
          this.addFieldsToValidate(['promocode.status']);
          this.validateField('promocode.status', this.promocode.status);
        } else {
          this.removeFieldsFromValidate(['promocode.status']);
        }
      },
    },
    useType: {
      handler(event) {
        if (event === 'multiple') {
          this.addFieldsToValidate(['promocode.countUsing']);
          this.validateField('promocode.countUsing', this.promocode.countUsing);
        } else {
          this.removeFieldsFromValidate(['promocode.countUsing']);
        }
      },
    },
    countType: {
      handler(event) {
        if (event === 'multiple') {
          this.addFieldsToValidate(['promocode.countUsers']);
          this.validateField('promocode.countUsers', this.promocode.countUsers);
        } else {
          this.removeFieldsFromValidate(['promocode.countUsers']);
        }
      },
    },
    generatePromocodes: {
      handler(event) {
        if (event) {
          this.addFieldsToValidate(['promocodesCount']);
          this.validateField('promocodesCount', this.promocodesCount);
          this.removeFieldsFromValidate(['promocode.code']);
        } else {
          this.addFieldsToValidate(['promocode.code']);
          this.validateField('promocode.code', this.promocode.code);
          this.removeFieldsFromValidate(['promocodesCount']);
        }
      },
    },
    priceType: {
      handler(event) {
        if (event === 'dynamic') {
          this.addFieldsToValidate(['promocode.firstUse']);
          if (this.secondarySale) {
            this.addFieldsToValidate(['promocode.secondaryUse', 'promocode.secondaryPrice']);
            if (this.thirdSale) this.addFieldsToValidate(['promocode.thirdUse', 'promocode.thirdPrice']);
          }
        } else {
          if (!this.secondarySale) this.removeFieldsFromValidate(['promocode.secondaryUse', 'promocode.secondaryPrice']);
          if (!this.thirdSale) this.removeFieldsFromValidate(['promocode.thirdUse', 'promocode.thirdPrice']);
          this.removeFieldsFromValidate(['promocode.firstUse']);
        }
      },
    },
    secondarySale: {
      handler(event) {
        if (event) {
          this.addFieldsToValidate(['promocode.secondaryUse', 'promocode.secondaryPrice']);
        } else if (this.priceType !== 'dynamic') this.removeFieldsFromValidate(['promocode.secondaryUse', 'promocode.secondaryPrice']);
      },
    },
    thirdSale: {
      handler(event) {
        if (event) {
          this.addFieldsToValidate(['promocode.thirdUse', 'promocode.thirdPrice']);
        } else if (this.priceType !== 'dynamic') this.removeFieldsFromValidate(['promocode.thirdUse', 'promocode.thirdPrice']);
      },
    },
    termType: {
      handler(event) {
        if (event === 'actual') {
          this.addFieldsToValidate(['promocode.endDate']);
          this.validateField(['promocode.endDate', this.promocode.endDate]);
        } else this.removeFieldsFromValidate(['promocode.endDate']);
      },
    },
    specType: {
      handler(event) {
        if (event === 'use') {
          this.addFieldsToValidate(['promocode.specializations']);
          this.removeFieldsFromValidate(['promocode.doctors']);
        } else if (event === 'doc') {
          this.addFieldsToValidate(['promocode.doctors']);
          this.removeFieldsFromValidate(['promocode.specializations']);
        } else {
          this.removeFieldsFromValidate(['promocode.specializations', 'promocode.doctors']);
        }
      },
    },
  },
  async created() {
    this.clinics = (await clinicService.getListNames()).map(({
      id,
      name,
      cityId,
      clinicAddress,
    }) => ({ id, name: `${name}, ${clinicAddress}`, cityId }));
    this.specializationList = await this.fetchSpecializations('');

    if (this.promocodeId) {
      try {
        this.isLoading = true;

        const [promocode] = await Promise.all([
          this.$store.dispatch(this.$types.PROMOCODE_FETCH, this.promocodeId),
        ]);

        this.promocode = {
          ...promocode,
          startDate: promocode.startDate ? parseISO(promocode.startDate) : null,
          endDate: promocode.endDate ? parseISO(promocode.endDate) : null,
        };
        if (promocode.type) {
          this.promocodeQueue = promocode.type;
        }
        // set Promocode Type
        if (promocode.isAbsolute) {
          this.promocodeType = 'absolute';
        } else if (promocode.isFixed) {
          this.promocodeType = 'fixed';
        } else if (promocode.isPerc) {
          this.promocodeType = 'perc';
        }

        // set Promocode Term Type
        if (promocode.startDate && promocode.endDate) {
          this.termType = 'actual';
        } else if (promocode.startDate && !promocode.endDate) {
          this.termType = 'limitless';
        }

        // set Promocode Use Type
        if (promocode.countUsing > 1) {
          this.useType = 'multiple';
        } else if (promocode.countUsing === 1) {
          this.useType = 'single';
        } else {
          this.useType = 'limitless';
        }

        // set Promocode Count Type
        if (promocode.countUsers > 0) {
          this.countType = 'multiple';
        } else {
          this.countType = 'limitless';
        }

        // set Promocode Price Type
        if (promocode.firstUse) {
          this.priceType = 'dynamic';
        } else {
          this.priceType = 'fixed';
        }

        // set Sales
        if (promocode.secondaryPrice || promocode.secondaryUse) {
          this.secondarySale = true;
        }
        if (promocode.thirdPrice || promocode.thirdUse) {
          this.thirdSale = true;
        }

        // set Promocode Spec Type
        if (promocode.specializations && promocode.specializations.length) {
          this.specType = 'use';
        } else if (promocode.doctors && promocode.doctors.length) {
          this.specType = 'doc';
        } else {
          this.specType = 'none';
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    }
    this.addFieldsToValidate(['promocode.code']);
  },
  methods: {
    makeValidate(fields) {
      const errors = makeValidate(fields, this, promocodeEditModalNewSchema);
      this.errors = [];
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
    validateField(field, value) {
      const error = validateData(field, value, promocodeEditModalNewSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },
    addFieldsToValidate(fields) {
      fields.forEach((field) => {
        if (!this.fieldsToValidate.includes(field)) {
          this.fieldsToValidate.push(field);
        }
      });
    },
    removeFieldsFromValidate(fields) {
      fields.forEach((field) => {
        const index = this.fieldsToValidate.indexOf(field);
        if (index !== -1) {
          this.fieldsToValidate.splice(index, 1);
        }
      });
    },
    onClose() {
      this.$emit('input', false);
    },
    changePromocodeType(type) {
      this.promocode.isFixed = false;
      this.promocode.isPerc = false;
      switch (type) {
        case 'fixed':
          this.promocode.isFixed = true;
          break;
        case 'perc':
          this.promocode.isPerc = true;
          break;
        default:
          break;
      }
      this.validateField('promocodeType', this.promocodeType);
    },
    changeUseType(type) {
      switch (type) {
        case 'single':
          this.promocode.countUsing = 1;
          break;
        case 'multiple':
          this.promocode.countUsing = 0;
          break;
        case 'limitless':
          this.promocode.countUsing = null;
          break;
        default:
          break;
      }
      this.validateField('useType', this.useType);
    },
    changeCountType(type) {
      this.validateField('countType', this.countType);
      switch (type) {
        case 'multiple':
          this.promocode.countUsers = 0;
          break;
        case 'limitless':
          this.promocode.countUsers = null;
          break;
        default:
          break;
      }
    },
    changePriceType(type) {
      this.validateField('priceType', this.priceType);
      switch (type) {
        case 'fixed':
          this.promocode.firstUse = null;
          this.promocode.secondaryUse = null;
          this.promocode.thirdUse = null;
          this.promocode.secondaryPrice = null;
          this.promocode.thirdPrice = null;
          this.secondarySale = false;
          this.thirdSale = false;
          break;
        case 'dynamic':
          break;
        default:
          break;
      }
    },
    changeSpecType(type) {
      this.validateField('specType', this.specType);
      switch (type) {
        case 'use':
          this.promocode.doctors = null;
          this.promocode.clinics = null;
          this.promocode.specializations = [];
          this.updateInput();
          break;
        case 'doc':
          this.promocode.specializations = null;
          this.promocode.doctors = [];
          this.promocode.clinics = [];
          this.updateInput();
          break;
        case 'none':
          this.promocode.specializations = null;
          this.promocode.doctors = null;
          this.promocode.clinics = null;
          break;
        default:
          break;
      }
    },
    changeTermType(type) {
      this.validateField('termType', this.termType);
      switch (type) {
        case 'actual':
          this.promocode.startDate = null;
          this.promocode.endDate = null;
          break;
        case 'limitless':
          this.promocode.startDate = new Date();
          this.promocode.endDate = null;
          break;
        default:
          break;
      }
    },
    async onClickSave() {
      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }

      this.isSaving = true;
      const promocode = {
        ...this.promocode,
        startDate: this.promocode.startDate ? formatISO(this.promocode.startDate) : null,
        endDate: this.promocode.endDate ? formatISO(this.promocode.endDate) : null,
        specializations: this.promocode.specializations ? this.promocode.specializations.map((spec) => {
          if (spec.id) {
            return spec.id;
          }
          return spec;
        }) : [],
        doctors: this.promocode.doctors ? this.promocode.doctors.map((doc) => {
          if (doc.id) {
            return doc.id;
          }
          return doc;
        }) : [],
        clinicIds: this.promocode.clinics ? this.promocode.clinics.map((clinic) => {
          if (clinic.id) {
            return clinic.id;
          }
          return clinic;
        }) : [],
        type: this.promocodeQueue === 0 ? 'Consultation' : 'DutyConsultation',
      };

      try {
        if (this.promocodeId) {
          await this.$store.dispatch(this.$types.PROMOCODE_UPDATE, promocode);
        } else if (this.generatePromocodes) {
          await this.$store.dispatch(this.$types.PROMOCODE_GENERATE, { count: this.promocodesCount, promocode });
        } else {
          await this.$store.dispatch(this.$types.PROMOCODE_CREATE, promocode);
          this.$emit('search');
        }

        this.$store.dispatch(this.$types.PROMOCODES_FETCH, true);
        this.onClose();
      } catch (e) {
        console.warn(e);
      } finally {
        this.isSaving = false;
      }
    },
    async fetchSpecializations(query) {
      const specialization = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);
      return specialization.sort((a, b) => a.name.localeCompare(b.name));
    },
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        save: false,
      });
      return doctors.map((doctor) => ({ ...doctor, name: doctor.fullName }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    onSpecialisationAdd(specId) {
      if (this.promocode.specializations.find((s) => s.id === specId)) return;

      const specialization = this.specializationList.find((s) => s.id === specId);
      if (specialization) this.promocode.specializations.push(specialization);
      this.validateField('promocode.specializations', this.promocode.specializations);
    },
    onSpecialisationRemove(specId) {
      this.promocode.specializations = this.promocode.specializations.filter((spec) => spec.id !== specId);
      this.validateField('promocode.specializations', this.promocode.specializations);
    },
    onDoctorAdd(doctorId) {
      if (this.promocode.doctors.find((d) => d.id === doctorId)) return;

      const doctor = this.doctors.find((d) => d.id === doctorId);
      if (doctor) this.promocode.doctors.push(doctor);
      this.validateField('promocode.doctors', this.promocode.doctors);
    },
    onDoctorRemove(doctorId) {
      this.promocode.doctors = this.promocode.doctors.filter((d) => d.id !== doctorId);
      this.validateField('promocode.doctors', this.promocode.doctors);
    },
    async onClinicAdd(clinicId) {
      if (this.promocode.clinics.find((c) => c.id === clinicId)) return;
      this.promocode.doctors = [];
      const clinic = this.clinics.find((c) => c.id === clinicId);
      if (clinic) this.promocode.clinics = [clinic];
      const { doctors } = await this.fetchClinicById(clinicId);
      this.doctors = [];
      doctors.forEach((d) => {
        const doctor = {
          id: d.id,
          name: `${d.lastName} ${d.firstName} ${d.middleName}`,
        };

        this.doctors.push(doctor);
      });
    },
    onClinicRemove(clinicId) {
      this.promocode.clinics = this.promocode.clinics.filter((c) => c.id !== clinicId);
      this.promocode.doctors = [];
    },
    async fetchClinicById(id) {
      try {
        this.isClinicLoading = true;
        const result = await clinicService.getOne(id);
        this.isClinicLoading = false;
        return result;
      } catch (err) {
        console.warn(err);
        showErrorCustomMessage('Не удалось получить информацию о клинике');
        this.isClinicLoading = false;
      }
    },
    addSecondarySale() {
      this.secondarySale = true;
    },
    addThirdSale() {
      this.thirdSale = true;
    },
    updateInput() {
      this.uniqId = uuidv4();
    },
  },
};
</script>

<style lang="scss" scoped>
  .td-width {
    width: 40px
  }
  .filtered-doctors {
    max-height: 210px;
    overflow-y: hidden;
  }

.checkbox-queue {
 margin: 27px 0px 0px 27px;
}

.checkbox-queue-2 {
  margin: 32px 0px 0px 27px;
}
</style>
