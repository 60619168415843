import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const promocodeEditModalNewSchema = z.object({
  promocodesCount: z
    .union([
      z.number().nullable().refine((value) => value > 0 && value !== null, {
        message: 'Некорректное значение поля',
      }),
      z.literal(''),
    ])
    .refine((value) => value !== '', {
      message: 'Некорректное значение поля',
    }),

  promocode: z.object({
    code: z.string().refine((code) => ((code && code.split(' ').length === 1
      && !/[а-яА-ЯЁё]/.test(code) && !Number.isInteger(+code))), { message: 'Некорректное значение поля' }),

    comment: z.string().min(1, 'Поле не может быть пустым'),

    isPerc: z.boolean().refine((val) => val !== null, {
      message: 'Телефон не подтвержден',
    }),

    isFixed: z.boolean().refine((val) => val !== null, {
      message: 'Телефон не подтвержден',
    }),

    startDate: z.date()
      .or(z.literal(null))
      .refine((date) => date !== null, 'Некорректное значение поля'),

    endDate: z.date()
      .or(z.literal(null))
      .refine((date) => date !== null, 'Некорректное значение поля'),

    countUsing: z.number().nullable().refine((count) => (count > 0),
      { message: 'Некорректное значение поля' }),
    countUsers: z.number().nullable().refine((count) => (count > 0),
      { message: 'Некорректное значение поля' }),
    valuePrice: z
      .union([
        z.number().nullable().refine((value) => value >= 0 && value !== null, {
          message: 'Некорректное значение поля',
        }),
        z.literal(''),
      ])
      .refine((value) => value !== '', {
        message: 'Некорректное значение поля',
      }),

    firstUse: z.number().nullable().refine((value) => value != null,
      { message: 'Поле не может быть пустым' }),

    secondaryUse: z.number().nullable().refine((value) => value != null,
      { message: 'Поле не может быть пустым' }),

    thirdUse: z.number().nullable().refine((value) => value != null,
      { message: 'Поле не может быть пустым' }),

    secondaryPrice: z.number().nullable().refine((value) => value != null,
      { message: 'Поле не может быть пустым' }),

    thirdPrice: z.number().nullable().refine((value) => value != null,
      { message: 'Поле не может быть пустым' }),

    attribute: z.number().nullable().refine((value) => value != null,
      { message: 'Выберите один из вариантов' }),

    status: z.number().nullable().refine((value) => value != null,
      { message: 'Выберите один из вариантов' }),

    specializations: z.array(z.object({})).min(1, 'Необходимо выбрать как минимум 1 специальность'),
    doctors: z.array(z.object({})).min(1, 'Необходимо выбрать как минимум 1 врача'),
  }),

  promocodeType: z.string().nullable().refine((value) => value != null, { message: 'Выберите один из вариантов' }),

  termType: z.string().nullable().refine((value) => value != null, { message: 'Выберите один из вариантов' }),

  useType: z.string().nullable().refine((value) => value != null, { message: 'Выберите один из вариантов' }),

  countType: z.string().nullable().refine((value) => value != null, { message: 'Выберите один из вариантов' }),

  specType: z.string().nullable().refine((value) => value != null, { message: 'Выберите один из вариантов' }),

  priceType: z.string().nullable().refine((value) => value != null, { message: 'Выберите один из вариантов' }),

});
